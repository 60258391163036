import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';

import * as DM from '../../../../fina-times-data';

import Constants from '../../../../common/utils/Constants';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useSisenseCompetitionCategoryDropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.EventCompetitionCategory.TypeName] };
    const { data } = useExecuteQuery(queryProps);
    const [competitionCategoryOptions, setCompetitionCategoryOptions] = useState({ options: [] });
    const { environmentVariableState, SISENSE_UNKNOWN_TEXT } = useEnvironmentVariableData();

    useEffect(() => {
        const defaultOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
        if (data && environmentVariableState.isLoaded === true) {
            const formattedOptions = [];
            formattedOptions.push(defaultOptions[0]);
            data.rows.map((row) => {
                if (row[0].text !== SISENSE_UNKNOWN_TEXT) {
                    return (formattedOptions.push({ id: row[0].data, name: row[0].text }))
                }
            });
            setCompetitionCategoryOptions({ ...competitionCategoryOptions, options: formattedOptions });
        }
        else {
            setCompetitionCategoryOptions({ ...competitionCategoryOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, environmentVariableState])

    return {
        data,
        competitionCategoryOptions
    };
};

export default useSisenseCompetitionCategoryDropdown;