import useSisenseCompetitionCategoryDropdown from "./UseSisenseCompetitionCategoryDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseCompetitionCategoryDropdown = ({ label, name, value, error, message, onChange }) => {
  const { competitionCategoryOptions } = useSisenseCompetitionCategoryDropdown();

  return !competitionCategoryOptions.options
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={competitionCategoryOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={!competitionCategoryOptions.options}
      />
    );
};

export default SisenseCompetitionCategoryDropdown;