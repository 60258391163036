import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import ManageTimes from './views/manageTimes/ManageTimes';
import TimeStandards from './views/timeStandards/TimeStandards';
import MyTimeStandards from './views/myTimeStandards/MyTimeStandards';
import RecordsAdmin from './views/recordsAdmin/RecordsAdmin';
import TimesAnomaly from './views/timesAnomalies/TimesAnomaly';
import TopTimes from './views/topTimes/TopTimes';
import NationalTeamTimesSearchReport from './views/nationalTeamTimesSearch/NationalTeamTimesSearchReport';

import PageNotFound from '../common/components/PageNotFound';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import UnexpectedError from '../common/components/UnexpectedError';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';

import useNavRoutes from '../common/state/security/UseNavRoutes';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes['TIMES_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TIMES_ROOT']}
                editElement={<ManageTimes />} />
            }
          />
          <Route path={navRoutes['TIME_STANDARDS_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TIME_STANDARDS_ROOT']}
                editElement={<TimeStandards />} />
            }
          />
          <Route path={navRoutes['MY_TIME_STANDARDS']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MY_TIME_STANDARDS']}
                editElement={<MyTimeStandards />} />
            }
          />
          <Route path={navRoutes['RECORDS_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['RECORDS_ROOT']}
                editElement={<RecordsAdmin />} />
            }
          />
          <Route path={navRoutes['TIMES_ANOMALY_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TIMES_ANOMALY_ROOT']}
                editElement={<TimesAnomaly />} />
            }
          />
          <Route path={navRoutes['TOP_TIMES_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TOP_TIMES_ROOT']}
                editElement={<TopTimes />} />
            }
          />
          <Route path={navRoutes['NATIONAL_TEAM_TIMES_SEARCH']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['NATIONAL_TEAM_TIMES_SEARCH']}
                editElement={<NationalTeamTimesSearchReport />} />
            }
          />

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;