import { Fragment } from "react";

import useNationalTeamTimesSearchDashboard from "./UseNationalTeamTimesSearchDashboard";

import NationalTeamTimesSearchWidget from "./NationalTeamTimesSearchWidget";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import Headings from "../../../common/components/headings/Headings";

const NationalTeamTimesSearchDashboard = ({ dashboardOid }) => {
  const { isError, isLoading, dashboard } = useNationalTeamTimesSearchDashboard(dashboardOid);

  if (isError) {
    return <p>Error</p>;
  }
  if (isLoading) {
    return <LoadingModal />;
  }
  if (dashboard) {
    return (
      <Fragment>
        <Headings.H3>{dashboard.title}</Headings.H3>
        {dashboard.widgets?.map((widget, i) => {
          if (i === 0) {
            return (
              <Fragment key={i}>
                <NationalTeamTimesSearchWidget dashboardOid={dashboardOid} widget={widget} />
              </Fragment>)
          }
        })
        }
      </Fragment>
    );
  }
  return <Fragment></Fragment>;
};

export default NationalTeamTimesSearchDashboard;