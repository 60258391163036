import type { Dimension, DateDimension, Attribute, DataSourceInfo } from '@sisense/sdk-data';

import { createAttribute, createDateDimension, createDimension } from '@sisense/sdk-data';

export const DataSource: DataSourceInfo = { title: 'FINA Times', type: 'elasticube' };

interface BestTimesDimension extends Dimension {
    AgeAtYearEndKey: Attribute;
    EventCompetitionCategoryKey: Attribute;
    FinaSwimTimeKey: Attribute;
    FullName: Attribute;
    MeetKey: Attribute;
    OrgUnitKey: Attribute;
    PersonKey: Attribute;
    PublicAgeGroupKey: Attribute;
    SeasonCalendarKey: Attribute;
    SortKey: Attribute;
    SwimEventKey: Attribute;
    SwimTimeFormatted: Attribute;
    SwimTimeSeconds: Attribute;
    TimeStandardKey: Attribute;
}
export const BestTimes = createDimension({
    name: 'BestTimes',
    AgeAtYearEndKey: createAttribute({
        name: 'AgeAtYearEndKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.AgeAtYearEndKey]',
    }),
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.EventCompetitionCategoryKey]',
    }),
    FinaSwimTimeKey: createAttribute({
        name: 'FinaSwimTimeKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.FinaSwimTimeKey]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[BestTimes.FullName]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.MeetKey]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.OrgUnitKey]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.PersonKey]',
    }),
    PublicAgeGroupKey: createAttribute({
        name: 'PublicAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.PublicAgeGroupKey]',
    }),
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.SeasonCalendarKey]',
    }),
    SortKey: createAttribute({
        name: 'SortKey',
        type: 'text-attribute',
        expression: '[BestTimes.SortKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.SwimEventKey]',
    }),
    SwimTimeFormatted: createAttribute({
        name: 'SwimTimeFormatted',
        type: 'text-attribute',
        expression: '[BestTimes.SwimTimeFormatted]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[BestTimes.SwimTimeSeconds]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[BestTimes.TimeStandardKey]',
    }),
}) as BestTimesDimension;

interface EventCompetitionCategoryDimension extends Dimension {
    CompetitionGenderTypeId: Attribute;
    EventCompetitionCategoryKey: Attribute;
    TypeCode: Attribute;
    TypeName: Attribute;
    ModifiedDatetime: DateDimension;
}
export const EventCompetitionCategory = createDimension({
    name: 'EventCompetitionCategory',
    CompetitionGenderTypeId: createAttribute({
        name: 'CompetitionGenderTypeId',
        type: 'numeric-attribute',
        expression: '[EventCompetitionCategory.CompetitionGenderTypeId]',
    }),
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[EventCompetitionCategory.EventCompetitionCategoryKey]',
    }),
    TypeCode: createAttribute({
        name: 'TypeCode',
        type: 'text-attribute',
        expression: '[EventCompetitionCategory.TypeCode]',
    }),
    TypeName: createAttribute({
        name: 'TypeName',
        type: 'text-attribute',
        expression: '[EventCompetitionCategory.TypeName]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[EventCompetitionCategory.ModifiedDatetime (Calendar)]',
    }),
}) as EventCompetitionCategoryDimension;

interface MeetDimension extends Dimension {
    MeetKey: Attribute;
    MeetName: Attribute;
}
export const Meet = createDimension({
    name: 'Meet',
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[Meet.MeetKey]',
    }),
    MeetName: createAttribute({
        name: 'MeetName',
        type: 'text-attribute',
        expression: '[Meet.MeetName]',
    }),
}) as MeetDimension;

interface NamedDateRangeDimension extends Dimension {
    EndDateInt: Attribute;
    NamedDateRangeKey: Attribute;
    NamedDateRangeName: Attribute;
    StartDateInt: Attribute;
    ModifiedDatetime: DateDimension;
}
export const NamedDateRange = createDimension({
    name: 'NamedDateRange',
    EndDateInt: createAttribute({
        name: 'EndDateInt',
        type: 'numeric-attribute',
        expression: '[NamedDateRange.EndDateInt]',
    }),
    NamedDateRangeKey: createAttribute({
        name: 'NamedDateRangeKey',
        type: 'numeric-attribute',
        expression: '[NamedDateRange.NamedDateRangeKey]',
    }),
    NamedDateRangeName: createAttribute({
        name: 'NamedDateRangeName',
        type: 'text-attribute',
        expression: '[NamedDateRange.NamedDateRangeName]',
    }),
    StartDateInt: createAttribute({
        name: 'StartDateInt',
        type: 'numeric-attribute',
        expression: '[NamedDateRange.StartDateInt]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[NamedDateRange.ModifiedDatetime (Calendar)]',
    }),
}) as NamedDateRangeDimension;

interface NationalTeamSelectionDimension extends Dimension {
    AgeAtYearEndKey: Attribute;
    EventCompetitionCategoryKey: Attribute;
    FinaSwimTimeKey: Attribute;
    FullName: Attribute;
    IsBestTime: Attribute;
    MeetKey: Attribute;
    NamedDateRangeKey: Attribute;
    OrgUnitKey: Attribute;
    PersonKey: Attribute;
    PublicAgeGroupKey: Attribute;
    SeasonCalendarKey: Attribute;
    SortKey: Attribute;
    SwimEventKey: Attribute;
    SwimTimeFormatted: Attribute;
    SwimTimeSeconds: Attribute;
    TimeStandardKey: Attribute;
}
export const NationalTeamSelection = createDimension({
    name: 'NationalTeamSelection',
    AgeAtYearEndKey: createAttribute({
        name: 'AgeAtYearEndKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.AgeAtYearEndKey]',
    }),
    EventCompetitionCategoryKey: createAttribute({
        name: 'EventCompetitionCategoryKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.EventCompetitionCategoryKey]',
    }),
    FinaSwimTimeKey: createAttribute({
        name: 'FinaSwimTimeKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.FinaSwimTimeKey]',
    }),
    FullName: createAttribute({
        name: 'FullName',
        type: 'text-attribute',
        expression: '[NationalTeamSelection.FullName]',
    }),
    IsBestTime: createAttribute({
        name: 'IsBestTime',
        type: 'text-attribute',
        expression: '[NationalTeamSelection.IsBestTime]',
    }),
    MeetKey: createAttribute({
        name: 'MeetKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.MeetKey]',
    }),
    NamedDateRangeKey: createAttribute({
        name: 'NamedDateRangeKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.NamedDateRangeKey]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.OrgUnitKey]',
    }),
    PersonKey: createAttribute({
        name: 'PersonKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.PersonKey]',
    }),
    PublicAgeGroupKey: createAttribute({
        name: 'PublicAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.PublicAgeGroupKey]',
    }),
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.SeasonCalendarKey]',
    }),
    SortKey: createAttribute({
        name: 'SortKey',
        type: 'text-attribute',
        expression: '[NationalTeamSelection.SortKey]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.SwimEventKey]',
    }),
    SwimTimeFormatted: createAttribute({
        name: 'SwimTimeFormatted',
        type: 'text-attribute',
        expression: '[NationalTeamSelection.SwimTimeFormatted]',
    }),
    SwimTimeSeconds: createAttribute({
        name: 'SwimTimeSeconds',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.SwimTimeSeconds]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[NationalTeamSelection.TimeStandardKey]',
    }),
}) as NationalTeamSelectionDimension;

interface OrgUnitDimension extends Dimension {
    Continent: Attribute;
    ContinentOrgUnitId: Attribute;
    IsCurrent: Attribute;
    OrganizationCode: Attribute;
    OrganizationName: Attribute;
    OrgUnitId: Attribute;
    OrgUnitKey: Attribute;
    TeamCode: Attribute;
    TeamName: Attribute;
}
export const OrgUnit = createDimension({
    name: 'OrgUnit',
    Continent: createAttribute({
        name: 'Continent',
        type: 'text-attribute',
        expression: '[OrgUnit.Continent]',
    }),
    ContinentOrgUnitId: createAttribute({
        name: 'ContinentOrgUnitId',
        type: 'numeric-attribute',
        expression: '[OrgUnit.ContinentOrgUnitId]',
    }),
    IsCurrent: createAttribute({
        name: 'IsCurrent',
        type: 'numeric-attribute',
        expression: '[OrgUnit.IsCurrent]',
    }),
    OrganizationCode: createAttribute({
        name: 'OrganizationCode',
        type: 'text-attribute',
        expression: '[OrgUnit.OrganizationCode]',
    }),
    OrganizationName: createAttribute({
        name: 'OrganizationName',
        type: 'text-attribute',
        expression: '[OrgUnit.OrganizationName]',
    }),
    OrgUnitId: createAttribute({
        name: 'OrgUnitId',
        type: 'numeric-attribute',
        expression: '[OrgUnit.OrgUnitId]',
    }),
    OrgUnitKey: createAttribute({
        name: 'OrgUnitKey',
        type: 'numeric-attribute',
        expression: '[OrgUnit.OrgUnitKey]',
    }),
    TeamCode: createAttribute({
        name: 'TeamCode',
        type: 'text-attribute',
        expression: '[OrgUnit.TeamCode]',
    }),
    TeamName: createAttribute({
        name: 'TeamName',
        type: 'text-attribute',
        expression: '[OrgUnit.TeamName]',
    }),
}) as OrgUnitDimension;

interface PublicAgeGroupDimension extends Dimension {
    AgeGroupDesc: Attribute;
    MaxAge: Attribute;
    MinAge: Attribute;
    PublicAgeGroupKey: Attribute;
    ModifiedDatetime: DateDimension;
}
export const PublicAgeGroup = createDimension({
    name: 'PublicAgeGroup',
    AgeGroupDesc: createAttribute({
        name: 'AgeGroupDesc',
        type: 'text-attribute',
        expression: '[PublicAgeGroup.AgeGroupDesc]',
    }),
    MaxAge: createAttribute({
        name: 'MaxAge',
        type: 'numeric-attribute',
        expression: '[PublicAgeGroup.MaxAge]',
    }),
    MinAge: createAttribute({
        name: 'MinAge',
        type: 'numeric-attribute',
        expression: '[PublicAgeGroup.MinAge]',
    }),
    PublicAgeGroupKey: createAttribute({
        name: 'PublicAgeGroupKey',
        type: 'numeric-attribute',
        expression: '[PublicAgeGroup.PublicAgeGroupKey]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[PublicAgeGroup.ModifiedDatetime (Calendar)]',
    }),
}) as PublicAgeGroupDimension;

interface SeasonCalendarDimension extends Dimension {
    CalendarYear: Attribute;
    FullDate: Attribute;
    ISLSeason: Attribute;
    MonthName: Attribute;
    MonthNumber: Attribute;
    MonthYear: Attribute;
    NCAASeason: Attribute;
    Quad: Attribute;
    SeasonCalendarKey: Attribute;
    SeasonYear: Attribute;
    SeasonYearDesc: Attribute;
    TheDay: Attribute;
    TheDayName: Attribute;
    CalendarDate: DateDimension;
    ModifiedDatetime: DateDimension;
    PriorYearDate: DateDimension;
}
export const SeasonCalendar = createDimension({
    name: 'SeasonCalendar',
    CalendarYear: createAttribute({
        name: 'CalendarYear',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.CalendarYear]',
    }),
    FullDate: createAttribute({
        name: 'FullDate',
        type: 'text-attribute',
        expression: '[SeasonCalendar.FullDate]',
    }),
    ISLSeason: createAttribute({
        name: 'ISLSeason',
        type: 'text-attribute',
        expression: '[SeasonCalendar.ISLSeason]',
    }),
    MonthName: createAttribute({
        name: 'MonthName',
        type: 'text-attribute',
        expression: '[SeasonCalendar.MonthName]',
    }),
    MonthNumber: createAttribute({
        name: 'MonthNumber',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.MonthNumber]',
    }),
    MonthYear: createAttribute({
        name: 'MonthYear',
        type: 'text-attribute',
        expression: '[SeasonCalendar.MonthYear]',
    }),
    NCAASeason: createAttribute({
        name: 'NCAASeason',
        type: 'text-attribute',
        expression: '[SeasonCalendar.NCAASeason]',
    }),
    Quad: createAttribute({
        name: 'Quad',
        type: 'text-attribute',
        expression: '[SeasonCalendar.Quad]',
    }),
    SeasonCalendarKey: createAttribute({
        name: 'SeasonCalendarKey',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.SeasonCalendarKey]',
    }),
    SeasonYear: createAttribute({
        name: 'SeasonYear',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.SeasonYear]',
    }),
    SeasonYearDesc: createAttribute({
        name: 'SeasonYearDesc',
        type: 'text-attribute',
        expression: '[SeasonCalendar.SeasonYearDesc]',
    }),
    TheDay: createAttribute({
        name: 'TheDay',
        type: 'numeric-attribute',
        expression: '[SeasonCalendar.TheDay]',
    }),
    TheDayName: createAttribute({
        name: 'TheDayName',
        type: 'text-attribute',
        expression: '[SeasonCalendar.TheDayName]',
    }),
    CalendarDate: createDateDimension({
        name: 'CalendarDate',
        expression: '[SeasonCalendar.CalendarDate (Calendar)]',
    }),
    ModifiedDatetime: createDateDimension({
        name: 'ModifiedDatetime',
        expression: '[SeasonCalendar.ModifiedDatetime (Calendar)]',
    }),
    PriorYearDate: createDateDimension({
        name: 'PriorYearDate',
        expression: '[SeasonCalendar.PriorYearDate (Calendar)]',
    }),
}) as SeasonCalendarDimension;

interface StandardTypeDimension extends Dimension {
    SortOrder: Attribute;
    StandardType: Attribute;
    TimeStandardKey: Attribute;
    TimeStandardName: Attribute;
}
export const StandardType = createDimension({
    name: 'StandardType',
    SortOrder: createAttribute({
        name: 'SortOrder',
        type: 'numeric-attribute',
        expression: '[StandardType.SortOrder]',
    }),
    StandardType: createAttribute({
        name: 'StandardType',
        type: 'text-attribute',
        expression: '[StandardType.StandardType]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[StandardType.TimeStandardKey]',
    }),
    TimeStandardName: createAttribute({
        name: 'TimeStandardName',
        type: 'text-attribute',
        expression: '[StandardType.TimeStandardName]',
    }),
}) as StandardTypeDimension;

interface SwimEventDimension extends Dimension {
    CourseCode: Attribute;
    EventCode: Attribute;
    SwimEventKey: Attribute;
}
export const SwimEvent = createDimension({
    name: 'SwimEvent',
    CourseCode: createAttribute({
        name: 'CourseCode',
        type: 'text-attribute',
        expression: '[SwimEvent.CourseCode]',
    }),
    EventCode: createAttribute({
        name: 'EventCode',
        type: 'text-attribute',
        expression: '[SwimEvent.EventCode]',
    }),
    SwimEventKey: createAttribute({
        name: 'SwimEventKey',
        type: 'numeric-attribute',
        expression: '[SwimEvent.SwimEventKey]',
    }),
}) as SwimEventDimension;

interface TimeStandardDimension extends Dimension {
    Division: Attribute;
    TimeStandardKey: Attribute;
    TimeStandardName: Attribute;
}
export const TimeStandard = createDimension({
    name: 'TimeStandard',
    Division: createAttribute({
        name: 'Division',
        type: 'text-attribute',
        expression: '[TimeStandard.Division]',
    }),
    TimeStandardKey: createAttribute({
        name: 'TimeStandardKey',
        type: 'numeric-attribute',
        expression: '[TimeStandard.TimeStandardKey]',
    }),
    TimeStandardName: createAttribute({
        name: 'TimeStandardName',
        type: 'text-attribute',
        expression: '[TimeStandard.TimeStandardName]',
    }),
}) as TimeStandardDimension;
