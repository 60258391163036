import React, { Fragment } from 'react';

import useTimeStandardAgeGroupAdd from './UseTimeStandardAgeGroupAdd';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const TimeStandardAgeGroupAdd = () => {
  const { timeStandardState, formState, errorState, handleSubmit, onFormValueChanged,
    onCancelClicked } = useTimeStandardAgeGroupAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Age Group</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Age Group Name*"
              name="ageGroupName"
              value={formState.ageGroupName}
              error={errorState.ageGroupName}
              message={errorState.ageGroupName}
              onChange={(value) => { onFormValueChanged('ageGroupName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Bonus?*"
              name="isBonus"
              checked={formState.isBonus === true}
              error={errorState.isBonus}
              message={errorState.isBonus}
              onChange={(value) => { onFormValueChanged('isBonus', value); }} />
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2">
            <Input
              label="Min Age*"
              name="minAge"
              type="number"
              min="1"
              max="99"
              value={formState.minAge}
              error={errorState.minAge}
              message={errorState.minAge}
              onChange={(value) => { onFormValueChanged('minAge', value) }} />
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2">
            <Input
              label="Max Age*"
              name="maxAge"
              type="number"
              min="1"
              max="99"
              value={formState.maxAge}
              error={errorState.maxAge}
              message={errorState.maxAge}
              onChange={(value) => { onFormValueChanged('maxAge', value) }} />
          </div>
        </div>
        {(errorState.duplicateNames || errorState.ageRangeOverlap) &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.duplicateNames ? errorState.duplicateNames : ''}</p>
              <p className={global.ErrorMessage}>{errorState.ageRangeOverlap ? errorState.ageRangeOverlap : ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isObjLoading && !timeStandardState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving} />
    </Fragment>
  )
}

export default TimeStandardAgeGroupAdd;
