import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';
import { filterFactory } from '@sisense/sdk-data';

import * as DM from '../../../../fina-times-data';

const useSisenseEventMultiselect = (courseName) => {
    const queryProps = {
        dataSource: DM.DataSource, dimensions: [DM.SwimEvent.SwimEventKey, DM.SwimEvent.EventCode],
        filters: courseName ? [filterFactory.contains(DM.SwimEvent.EventCode, courseName)] : null
    };
    const { data, isLoading } = useExecuteQuery(queryProps);
    const [eventOptions, setEventOptions] = useState({ options: [] });

    useEffect(() => {
        const defaultOptions = [];
        if (data) {
            const formattedOptions = [];
            data.rows.map((row) => {
                if (row[0].data !== 0) {
                    return (formattedOptions.push({ id: row[0].data, name: row[1].text, children: [] }))
                }
            });
            setEventOptions({ ...eventOptions, options: formattedOptions });
        }
        else {
            setEventOptions({ ...eventOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, courseName])

    return {
        data,
        isLoading,
        eventOptions
    };
};

export default useSisenseEventMultiselect;