import { useEffect, useState } from 'react';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useSisenseBestTimeOnlyDropdown = () => {
    const [bestTimeOnlyOptions, setBestTimeOnlyOptions] = useState({ options: [] });
    const { environmentVariableState, SISENSE_SHOW_BEST_TIMES_ONLY_TEXT,
        SISENSE_SHOW_ALL_TIMES_TEXT } = useEnvironmentVariableData();

    useEffect(() => {
        if (environmentVariableState.isLoaded === true) {
            const formattedOptions = [{
                id: 1, name: SISENSE_SHOW_BEST_TIMES_ONLY_TEXT
            },
            {
                id: 2, name: SISENSE_SHOW_ALL_TIMES_TEXT
            }];
            setBestTimeOnlyOptions({ ...bestTimeOnlyOptions, options: formattedOptions });
        }
        else {
            setBestTimeOnlyOptions({ ...bestTimeOnlyOptions, options: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environmentVariableState])

    return {
        bestTimeOnlyOptions
    };
};

export default useSisenseBestTimeOnlyDropdown;