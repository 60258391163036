import React, { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, onDelete }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.objData.timeStandardAgeGroup);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age Group Name'} columnField={'ageGroupName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Bonus'} columnField={'isBonus'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Min Age'} columnField={'minAge'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Max Age'} columnField={'maxAge'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isObjLoaded !== true
          ? (
            <tr>
              {state.isObjLoading === true ?
                <td colSpan="5">Loading...</td>
                : <td colSpan="5">No Age Groups</td>
              }
            </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((ageGroup, i) => (
              <tr key={i}>
                <td>{ageGroup.ageGroupName}</td>
                <td>{ageGroup.isBonus === true ? 'Yes' : 'No'}</td>
                <td>{ageGroup.minAge}</td>
                <td>{ageGroup.maxAge}</td>
                <td>
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, ageGroup.timeStandardAgeGroupId)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="5">No Age Groups</td>
              </tr>
            )
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({ state, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          {state.isObjLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No Age Groups</div>
          }
        </div>
      ) : Array.isArray(state.objData.timeStandardAgeGroup) && state.objData.timeStandardAgeGroup.length > 0
        ? state.objData.timeStandardAgeGroup.map((ageGroup, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{ageGroup.ageGroupName}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, ageGroup.timeStandardAgeGroupId)}>
                <DeleteIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Age Group Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{ageGroup.ageGroupName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Bonus?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.isBonus === true ? 'Yes' : 'No'}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Min Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.minAge}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Max Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{ageGroup.maxAge}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Age Groups</div>
          </div>
        )
    }
  </div >
);

const TimeStandardAgeGroupGrid = ({ state, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} onDelete={onDelete} />
    <SmallGrid state={state} onDelete={onDelete} />
  </Fragment>
);

export default TimeStandardAgeGroupGrid;