import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postTimeFilesLogoData from './PostTimeFilesLogoData';

const BASE_UPLOAD_URL = 'https://timesdocs.blob.core.windows.net/logos/';
const GET_TIME_FILES_LOGO_PATH = '/TimeFiles/logo?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimeFilesLogoData = {
  BASE_UPLOAD_URL,
  GET_TIME_FILES_LOGO_PATH,
  INITIAL_STATE,
  postTimeFilesLogoData
};

export default TimeFilesLogoData;