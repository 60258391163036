import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useNationalTeamTimesSearchReport = () => {
    const { environmentVariableState, NATIONAL_TIMES_SEARCH_OID } = useEnvironmentVariableData();

    return {
        environmentVariableState, NATIONAL_TIMES_SEARCH_OID
    };
};

export default useNationalTeamTimesSearchReport;