import { Fragment } from "react";

import useNationalTeamTimesSearchWidget from "./UseNationalTeamTimesSearchWidget";

import SisenseDateRangeDropdown from '../../components/fina-times-data/sisenseDateRangeDropdown/SisenseDateRangeDropdown';
import SisenseCompetitionCategoryDropdown from '../../components/fina-times-data/sisenseCompetitionCategoryDropdown/SisenseCompetitionCategoryDropdown';
import SisenseBestTimeOnlyDropdown from '../../components/fina-times-data/sisenseBestTimeOnlyDropdown/SisenseBestTimeOnlyDropdown';
import SisenseCourseDropdown from '../../components/fina-times-data/sisenseCourseDropdown/SisenseCourseDropdown';
import SisenseEventMultiselect from '../../components/fina-times-data/sisenseEventMultiselect/SisenseEventMultiselect';

import Spinner from "../../../common/components/spinners/Spinner";
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import LeftArrowIcon from "../../../common/components/icons/LeftArrowIcon";
import RightArrowIcon from "../../../common/components/icons/RightArrowIcon";
import Input from "../../../common/components/inputs/Input";

import ToIntIfInt from "../../../common/utils/ToIntIfInt";
import Constants from "../../../common/utils/Constants";

import global from '../../../common/components/GlobalStyle.module.css';
import style from './CustomWidgetStyle.module.css';

const NationalTeamTimesSearchGridRowSmall = ({ columns, row }) => {
  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        &nbsp;</div>
      <div className={global.SmallTableRowInfo}>
        <div className="row">
          {columns.map((column, i) => (
            <Fragment key={i}>
              {column.columnNumber > 0 && column.columnNumber < 8 &&
                <Fragment>
                  <div className={i === 0 ? [global.SmallTableRowLabels, 'col-xs-6'].join(' ') :
                    [global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>{column.name ? column.name === column.name?.toUpperCase() ?
                      column.name?.match(/[A-Z]+|[0-9]+/g)?.join(" ") :
                      column.name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") : ''}</div>
                  <div className={i === 0 ? [global.SmallTableRowData, 'col-xs-6'].join(' ') :
                    [global.SmallTableRowData, 'col-xs-6'].join(' ')}>
                    {row[i]?.text ?
                      row[i]?.text === 'true' ? 'Yes'
                        : row[i]?.text === 'false' ? <span>&nbsp;</span>
                          : row[i]?.text
                      : <span>&nbsp;</span>}</div>
                </Fragment>}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

const NationalTeamTimesSearchGridSmall = ({ data, eventOptions, backgroundEventIndexState }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(data.rows) && data.rows.length > 0
      ? data.rows.map((row, i) => (
        <NationalTeamTimesSearchGridRowSmall key={i} columns={data.columns} row={row} />
      )) : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No {eventOptions.options[backgroundEventIndexState]?.name} Results</div>
          </div>
        </div>
      )}
  </div>
);

const NationalTeamTimesSearchGridRowLarge = ({ row }) => (
  <tr>
    {row.map((cell, i) => {
      if (cell.columnNumber === 5) {
        return (<td className={style.MeetName} key={i}>{cell?.text ? cell?.text === 'true' ? 'Yes' : cell?.text === 'false' ? '' : (cell?.text) : ''}</td>)
      }
      else if (cell.columnNumber > 0 && cell.columnNumber < 8) {
        return (
          <td key={i}>
            {cell?.text ?
              cell?.text === 'true' ? 'Yes'
                : cell?.text === 'false' ? ''
                  : cell?.text
              : ''}
          </td>)
      }
    })}
  </tr>
);

const NationalTeamTimesSearchGridLarge = ({ data, eventOptions, backgroundEventIndexState }) => {
  return (
    <table className={global.UsasTable}>
      <thead>
        <tr>
          {data.columns.map((column, i) =>
            <Fragment key={i}>
              {column.columnNumber === 3 ?
                <th key={i} style={{ minWidth: '120px' }}>
                  {column.name ? column.name === column.name?.toUpperCase() ?
                    column.name?.match(/[A-Z]+|[0-9]+/g)?.join(" ") :
                    column.name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") : ''}</th> :
                column.columnNumber === 6 || column.columnNumber === 7 ?
                  <th key={i} style={{ minWidth: '80px' }}>
                    {column.name ? column.name === column.name?.toUpperCase() ?
                      column.name?.match(/[A-Z]+|[0-9]+/g)?.join(" ") :
                      column.name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") : ''}</th> :
                  column.columnNumber > 0 && column.columnNumber < 8 ?
                    <th key={i}>
                      {column.name ? column.name === column.name?.toUpperCase() ?
                        column.name?.match(/[A-Z]+|[0-9]+/g)?.join(" ") :
                        column.name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") : ''}</th> :
                    <Fragment />}
            </Fragment>)}
        </tr>
      </thead>
      <tbody>
        {data.rows.length > 0
          ? data.rows.map((row, i) => <NationalTeamTimesSearchGridRowLarge key={i} row={row} />)
          : <tr><td colSpan={data.columns.length}>No {eventOptions.options[backgroundEventIndexState]?.name} Results</td></tr>
        }
      </tbody>
    </table>
  );
}

const NationalTeamTimesSearchGrid = ({ data, eventOptions, backgroundEventIndexState }) => (
  <Fragment>
    <div className={'visible-md visible-lg'}>
      <NationalTeamTimesSearchGridLarge data={data} eventOptions={eventOptions} backgroundEventIndexState={backgroundEventIndexState} />
    </div>
    <div className={'hidden-md hidden-lg'}>
      <NationalTeamTimesSearchGridSmall data={data} eventOptions={eventOptions} backgroundEventIndexState={backgroundEventIndexState} />
    </div>
  </Fragment>
);

const NationalTeamTimesSearchWidget = ({ dashboardOid, widget }) => {
  const {
    isError,
    isLoading,
    state,
    formattedData,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    eventOptions,
    eventOptionsFilteredByCourse,
    backgroundEventIndexState
  } = useNationalTeamTimesSearchWidget(dashboardOid, widget);

  return (
    <div>
      <div className={state.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseDateRangeDropdown
              label="Date Range*"
              name="dateRangeId"
              value={formState.dateRangeId}
              error={formState.dateRangeId === Constants.DEFAULT_ID ||
                formState.dateRangeName === '--'}
              message={'Date Range is required'}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'dateRangeId', newValueLabel, 'dateRangeName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseCompetitionCategoryDropdown
              label="Gender"
              name="competitionCategoryId"
              value={formState.competitionCategoryId}
              error={errorState.competitionCategoryId}
              message={errorState.competitionCategoryId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'competitionCategoryId', newValueLabel, 'competitionCategoryName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseBestTimeOnlyDropdown
              label="Display Options*"
              name="displayId"
              value={formState.displayId}
              error={formState.displayId === Constants.DEFAULT_ID ||
                formState.displayName === '--'}
              message={'Display Option is required'}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'displayId', newValueLabel, 'displayName');
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <SisenseCourseDropdown
              label="Course"
              name="courseId"
              value={formState.courseId}
              error={errorState.courseId}
              message={errorState.courseId}
              onChange={(value, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(value, 'courseId', newValueLabel, 'courseName');
              }}
            />
          </div>
          {state.initialLoadComplete === true && <div className="col-xs-12">
            <SisenseEventMultiselect
              label="Event"
              name="events"
              value={formState.events}
              error={errorState.events}
              message={errorState.events}
              onChange={(value) => { onFormValueChanged('events', value); }}
              courseName={formState.courseName !== '--' ? formState.courseName : undefined}
            />
          </div>}
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label={"Max Results*"}
              type="number"
              name="maxResults"
              min='1'
              max='500'
              value={formState.maxResults}
              error={formState.maxResults && (ToIntIfInt(formState.maxResults) < 1 || ToIntIfInt(formState.maxResults) > 500) || !formState.maxResults}
              message={'Must be a number between 1 and 500'}
              onChange={(value) => { onFormValueChanged('maxResults', value) }} />
          </div>
        </div>
      </div>
      <div className={style.Widget}>
        <div className="col-xs-12">
          <button className={style.ToggleButton} type="button" onClick={(e) => onFiltersToggle(e)}>{state.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
        </div>
      </div>
      {!isLoading && !isError &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-4">
              <p>{formattedData.rows?.length === 1 ? `${eventOptions.options[backgroundEventIndexState]?.name} (${formattedData.rows?.length} Result` : ` ${eventOptions.options[backgroundEventIndexState]?.name} (${formattedData.rows?.length} Results`} - Page {state.pageNumber})</p>
            </div>
            {formState.maxResults && (ToIntIfInt(formState.maxResults) > 0 && ToIntIfInt(formState.maxResults) < 501) &&
              <div className="col-xs-12 col-sm-8">
                {((formState.events?.length === 0 && (formState.courseName === '--' ? backgroundEventIndexState + 2 <= eventOptions.options?.length :
                  eventOptions?.options[backgroundEventIndexState]?.id < eventOptionsFilteredByCourse[eventOptionsFilteredByCourse.length - 1]?.id))
                  || (formState.events?.length > 1 && ((formState.events?.findIndex((event) => event.name === eventOptions.options[backgroundEventIndexState]?.name) + 1) < formState.events?.length)))
                  &&
                  <Fragment>
                    <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Event <RightArrowIcon /></SecondaryButton>
                    <span className="pull-right">&nbsp;</span>
                  </Fragment>}
                {(state.offset > 0 || (formState.events?.length === 0 && backgroundEventIndexState > 0 && state.pageNumber > 1) ||
                  (formState.events?.length > 1 && state.pageNumber > 1)) &&
                  <Fragment>
                    <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Event</SecondaryButton>
                    <span className="pull-right">&nbsp;</span>
                  </Fragment>}
              </div>}
          </div>
        </div>}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {isError
            ? <p className={global.ErrorMessage}>Error...</p>
            : isLoading
              ? <Spinner />
              : formattedData
                ?
                <NationalTeamTimesSearchGrid data={formattedData || {}} eventOptions={eventOptions} backgroundEventIndexState={backgroundEventIndexState} />
                : <Fragment />
          }
        </div>
      </div>
      {!isLoading && !isError && formattedData.rows?.length > 5 &&
        <div className={style.Widget}>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12 col-sm-4">
              <p>{formattedData.rows?.length === 1 ? `${eventOptions.options[backgroundEventIndexState]?.name} (${formattedData.rows?.length} Result` : ` ${eventOptions.options[backgroundEventIndexState]?.name} (${formattedData.rows?.length} Results`} - Page {state.pageNumber})</p>
            </div>
            {formState.maxResults && (ToIntIfInt(formState.maxResults) > 0 && ToIntIfInt(formState.maxResults) < 501) &&
              <div className="col-xs-12 col-sm-8">
                {((formState.events?.length === 0 && (formState.courseName === '--' ? backgroundEventIndexState + 2 <= eventOptions.options?.length :
                  eventOptions?.options[backgroundEventIndexState]?.id < eventOptionsFilteredByCourse[eventOptionsFilteredByCourse.length - 1]?.id))
                  || (formState.events?.length > 1 && ((formState.events?.findIndex((event) => event.name === eventOptions.options[backgroundEventIndexState]?.name) + 1) < formState.events?.length)))
                  &&
                  <Fragment>
                    <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToNextPage(e)} >View Next Event <RightArrowIcon /></SecondaryButton>
                    <span className="pull-right">&nbsp;</span>
                  </Fragment>}
                {(state.offset > 0 || (formState.events?.length === 0 && backgroundEventIndexState > 0 && state.pageNumber > 1) ||
                  (formState.events?.length > 1 && state.pageNumber > 1)) &&
                  <Fragment>
                    <SecondaryButton className={'pull-right usas-extra-top-margin'} onClick={(e) => goToPreviousPage(e)} ><LeftArrowIcon /> View Previous Event</SecondaryButton>
                    <span className="pull-right">&nbsp;</span>
                  </Fragment>}
              </div>}
          </div>
        </div>}
    </div>
  );
};

export default NationalTeamTimesSearchWidget;