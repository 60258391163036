import { Fragment } from "react";

import { SisenseContextProvider } from "@sisense/sdk-ui";

import useNationalTeamTimesSearchReport from "./UseNationalTeamTimesSearchReport";

import * as DM from '../../../fina-times-data';

import NationalTeamTimesSearchDashboard from "./NationalTeamTimesSearchDashboard";

const NationalTeamTimesSearchReport = () => {
  const { environmentVariableState, NATIONAL_TIMES_SEARCH_OID } = useNationalTeamTimesSearchReport();

  return (
    <Fragment>
      {environmentVariableState.isLoaded === true &&
        <SisenseContextProvider
          defaultDataSource={DM.DataSource}
          url={import.meta.env.VITE_SISENSE_URL}
          token={import.meta.env.VITE_SISENSE_API_TOKEN}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <NationalTeamTimesSearchDashboard dashboardOid={NATIONAL_TIMES_SEARCH_OID} />
            </div>
          </div>
        </SisenseContextProvider>}
    </Fragment>
  );
}

export default NationalTeamTimesSearchReport;