import { useState } from 'react';

import TimeFilesLogoData from './TimeFilesLogoData';

const useTimeFilesLogoData = () => {
  const [timeFilesLogoState, setTimeFilesLogoState] = useState(TimeFilesLogoData.INITIAL_STATE);

  const postTimeFilesLogo = (file) => {
    TimeFilesLogoData.postTimeFilesLogoData(file, timeFilesLogoState, setTimeFilesLogoState);
  };

  const resetTimeFilesLogoState = () => {
    setTimeFilesLogoState(TimeFilesLogoData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (timeFilesLogoState.isObjLoaded === true) {
      const fileNameWithDateTime = timeFilesLogoState.objData.uploadUrl.replace(TimeFilesLogoData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return timeFilesLogoState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(TimeFilesLogoData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    timeFilesLogoState,
    postTimeFilesLogo,
    resetTimeFilesLogoState,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    BASE_UPLOAD_URL: TimeFilesLogoData.BASE_UPLOAD_URL,
    GET_TIME_FILES_LOGO_PATH: TimeFilesLogoData.GET_TIME_FILES_LOGO_PATH
  };
};

export default useTimeFilesLogoData;