import { useEffect, useMemo, useState } from "react";
import { useLocation } from "../../../common/wrappers/ReactRouterDom";

import { useExecuteQueryByWidgetId } from "@sisense/sdk-ui";
import { filterFactory } from "@sisense/sdk-data";

import * as DM from '../../../fina-times-data';

import useSisenseEventMultiselect from "../../components/fina-times-data/sisenseEventMultiselect/UseSisenseEventMultiselect";
import useSisenseBestTimeOnlyDropdown from "../../components/fina-times-data/sisenseBestTimeOnlyDropdown/UseSisenseBestTimeOnlyDropdown";
import useSisenseCompetitionCategoryDropdown from "../../components/fina-times-data/sisenseCompetitionCategoryDropdown/UseSisenseCompetitionCategoryDropdown";
import useSisenseDateRangeDropdown from "../../components/fina-times-data/sisenseDateRangeDropdown/UseSisenseDateRangeDropdown";

import useEnvironmentVariableData from "../../../common/state/environmentVariable/UseEnvironmentVariableData";

import UseForm from "../../../common/utils/UseForm";
import Constants from "../../../common/utils/Constants";
import ToIntIfInt from "../../../common/utils/ToIntIfInt";

const INITIAL_FORM_STATE = {
  dateRangeId: Constants.DEFAULT_ID,
  dateRangeName: '--',
  competitionCategoryId: Constants.DEFAULT_ID,
  competitionCategoryName: '--',
  courseId: Constants.DEFAULT_ID,
  courseName: '--',
  events: [],
  displayId: Constants.DEFAULT_ID,
  displayName: '--',
  maxResults: 100
};

const useNationalTeamTimesSearchWidget = (dashboardOid, widget) => {
  const location = useLocation();
  const [state, setState] = useState({ offset: 0, button: '', pageNumber: 1, showFilters: true, initialLoadComplete: false, isDefaultDateRangeSet: false, isDefaultCompetitionCategorySet: false });
  const [backgroundEventIndexState, setBackgroundEventIndexState] = useState(0);
  const [dateRangeFilter, setDateRangeFilter] = useState(null);
  const [typeNameFilter, setTypeNameFilter] = useState(null);
  const [courseFilter, setCourseFilter] = useState(null);
  const [eventCodeFilter, setEventCodeFilter] = useState(null);
  const [displayFilter, setDisplayFilter] = useState(null);
  const [formattedData, setFormattedData] = useState({ rows: [], columns: [] });
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, setFormData } = UseForm(INITIAL_FORM_STATE, () => { }, () => { });
  const { dateRangeOptions } = useSisenseDateRangeDropdown();
  const { eventOptions } = useSisenseEventMultiselect();
  const { competitionCategoryOptions } = useSisenseCompetitionCategoryDropdown();
  const { bestTimeOnlyOptions } = useSisenseBestTimeOnlyDropdown();
  const { environmentVariableState, SISENSE_SHOW_ALL_TIMES_TEXT, SISENSE_SHOW_BEST_TIMES_ONLY_TEXT } = useEnvironmentVariableData();

  const filters = useMemo(() => {
    return [dateRangeFilter, typeNameFilter, courseFilter, eventCodeFilter, displayFilter].filter((f) => !!f);
  }, [dateRangeFilter, typeNameFilter, courseFilter, eventCodeFilter, displayFilter]);

  const { data, isLoading, isError } = useExecuteQueryByWidgetId({
    dashboardOid,
    widgetOid: widget?.oid,
    enabled: state.initialLoadComplete === true && dateRangeFilter !== null && (formState.maxResults > 0 && formState.maxResults < 501) && eventCodeFilter !== null,
    filters: filters,
    count: 500,
    offset: state.offset
  });

  const onFiltersToggle = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showFilters: !state.showFilters });
  };

  const goToNextPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let newOffset = state.offset + 500;
    let backgroundEventIndex = backgroundEventIndexState;
    if (formState.events.length === 0 && formState.courseName === '--' && (backgroundEventIndex + 1 <= eventOptions.options?.length)) {
      if (eventOptions.options[backgroundEventIndexState + 1]?.name) {
        const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[backgroundEventIndexState + 1]?.name]);
        setEventCodeFilter(events);
      }
      newOffset = 0;
      backgroundEventIndex = backgroundEventIndexState + 1;
    }
    else if (formState.events.length === 0 && formState.courseName !== '--') {
      let eventOptionsFilteredByCourse = eventOptions.options.filter((option) => option?.name?.includes(formState.courseName))
      if (eventOptions.options[backgroundEventIndexState + 1]?.id <= eventOptionsFilteredByCourse[eventOptionsFilteredByCourse?.length - 1]?.id) {
        if (eventOptions.options[backgroundEventIndexState + 1]?.name) {
          const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[backgroundEventIndexState + 1]?.name]);
          setEventCodeFilter(events);
        }
        backgroundEventIndex = backgroundEventIndexState + 1;
      }
      newOffset = 0;
    }
    else if (formState.events.length > 0) {
      let currentEventSelection = eventOptions.options[backgroundEventIndexState];
      let currentFormStateEventSelectionIndex = formState.events?.findIndex((event) => event.name === currentEventSelection?.name);
      if (currentFormStateEventSelectionIndex > -1) {
        if (currentFormStateEventSelectionIndex + 1 < formState.events?.length) {
          if (formState.events[currentFormStateEventSelectionIndex + 1]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [formState.events[currentFormStateEventSelectionIndex + 1]?.name]);
            setEventCodeFilter(events);
          }
          backgroundEventIndex = eventOptions.options.findIndex((option) => option.name === formState.events[currentFormStateEventSelectionIndex + 1]?.name);
        }
      }
      newOffset = 0;
    }

    setState({ ...state, offset: newOffset, button: 'next', pageNumber: state.pageNumber + 1 });
    setBackgroundEventIndexState(backgroundEventIndex);
  };

  const goToPreviousPage = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let newOffset = state.offset + 500;
    let backgroundEventIndex = backgroundEventIndexState;
    if (formState.events.length === 0 && formState.courseName === '--' && (backgroundEventIndexState - 1 >= 0)) {
      if (eventOptions.options[backgroundEventIndexState - 1]?.name) {
        const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[backgroundEventIndexState - 1]?.name]);
        setEventCodeFilter(events);
      }
      newOffset = 0;
      backgroundEventIndex = backgroundEventIndexState - 1;
    }
    else if (formState.events.length === 0 && formState.courseName !== '--') {
      let eventOptionsFilteredByCourse = eventOptions.options.filter((option) => option?.name?.includes(formState.courseName))
      if (eventOptions.options[backgroundEventIndexState - 1]?.id >= eventOptionsFilteredByCourse[0]?.id) {
        if (eventOptions.options[backgroundEventIndexState - 1]?.name) {
          const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[backgroundEventIndexState - 1]?.name]);
          setEventCodeFilter(events);
        }
        backgroundEventIndex = backgroundEventIndexState - 1;
      }
      newOffset = 0;
    }
    else if (formState.events.length > 0) {
      let currentEventSelection = eventOptions.options[backgroundEventIndexState];
      let currentFormStateEventSelectionIndex = formState.events?.findIndex((event) => event.name === currentEventSelection?.name);
      if (currentFormStateEventSelectionIndex > -1) {
        if (currentFormStateEventSelectionIndex - 1 >= 0) {
          if (formState.events[currentFormStateEventSelectionIndex - 1]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [formState.events[currentFormStateEventSelectionIndex - 1]?.name]);
            setEventCodeFilter(events);
          }
          backgroundEventIndex = eventOptions.options.findIndex((option) => option.name === formState.events[currentFormStateEventSelectionIndex - 1]?.name);
        }
      }
      newOffset = 0;
    }

    if (state.button === 'nextLast') {
      newOffset = 0;
      setState({ ...state, offset: newOffset, button: 'previous', pageNumber: state.pageNumber - 1 });
    }
    else {
      setState({ ...state, offset: newOffset, button: 'previous', pageNumber: state.pageNumber - 1 });
      setBackgroundEventIndexState(backgroundEventIndex);
    }
  };

  useEffect(() => {
    //Reset previous filters on navigating back to page
    if (state.initialLoadComplete === false) {
      if (location.state && (location.state.dateRangeFilter || location.state.typeNameFilter ||
        location.state.courseFilter || location.state.eventCodeFilter || location.state.displayFilter)) {
        if (location.state.dateRangeFilter) {
          const dateRange = filterFactory.members(DM.NamedDateRange.NamedDateRangeName, location.state.dateRangeFilter?.members);
          setDateRangeFilter(dateRange);
        }
        if (location.state.typeNameFilter) {
          const typeName = filterFactory.members(DM.EventCompetitionCategory.TypeName, location.state.typeNameFilter?.members);
          setTypeNameFilter(typeName);
        }
        if (location.state.courseFilter) {
          const course = filterFactory.members(DM.SwimEvent.CourseCode, location.state.courseFilter?.members);
          setCourseFilter(course);
        }
        if (location.state.eventCodeFilter && location.state.events.length > 0) {
          if (location.state.events[0]?.name) {
            const eventCode = filterFactory.members(DM.SwimEvent.EventCode, [location.state.events[0].name]);
            setEventCodeFilter(eventCode);
          }
        }
        if (location.state.displayFilter) {
          const display = filterFactory.equals(DM.NationalTeamSelection.IsBestTime, location.state.displayFilter.valueA);
          setDisplayFilter(display);
        }
        if (location.state.dateRangeId || location.state.dateRangeName ||
          location.state.competitionCategoryId || location.state.competitionCategoryName ||
          location.state.courseId || location.state.courseName || location.state.events ||
          location.state.displayId || location.state.displayName ||
          location.state.maxResults) {
          setFormData({
            ...formState,
            dateRangeId: location.state.dateRangeId || Constants.DEFAULT_ID,
            dateRangeName: location.state.dateRangeName || '--',
            competitionCategoryId: location.state.competitionCategoryId || Constants.DEFAULT_ID,
            competitionCategoryName: location.state.competitionCategoryName || '--',
            courseId: location.state.courseId || Constants.DEFAULT_ID,
            courseName: location.state.courseName || '--',
            events: location.state.events || [],
            displayId: location.state.displayId || bestTimeOnlyOptions.options[0]?.id,
            displayName: location.state.displayName || bestTimeOnlyOptions.options[0]?.name,
            maxResults: location.state.maxResults || 100
          });
        }

        let eventsHandled = false;
        //if there are no location.state.events and the event dropdown is loaded, consider the page loaded
        if (!location.state.events && eventOptions.options?.length > 0) {
          eventsHandled = true;
        }
        //if there are location.state.events and the event dropdown is loaded and the formState.events length
        //is the same as the location.state.events, consider the page loaded
        if ((location.state.events?.length === formState.events?.length) && eventOptions.options?.length > 0) {
          eventsHandled = true;
        }

        let backgroundEventIndexHandled = false;
        if (eventOptions.options?.length > 0 && location.state.events?.length === 0 && location.state.courseName === '--') {
          //Set an event code in the background if there is no location.state.events to avoid issues with maxResults
          let eventOptionsIndex = 0;
          eventOptionsIndex = eventOptions.options.findIndex(option => option.name === '50 FR SCM');
          if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
            setEventCodeFilter(events);
            setBackgroundEventIndexState(eventOptionsIndex);
          }
          backgroundEventIndexHandled = true;
        }
        else if (eventOptions.options?.length > 0 && location.state.events?.length === 0 && location.state.courseName !== '--') {
          //Set an event code in the background if there is no location.state.events to avoid issues with maxResults
          let eventOptionsIndex = 0;
          eventOptionsIndex = eventOptions.options.findIndex(option => option.name === `50 FR ${formState.courseName}`);
          if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
            setEventCodeFilter(events);
            setBackgroundEventIndexState(eventOptionsIndex);
          }
          backgroundEventIndexHandled = true;
        }
        else if (eventOptions.options?.length > 0 && formState.timeStandardName !== '--' && location.state.events?.length > 0) {
          //Set an event code equal to the first item in the location.state.events array to avoid issues with maxResults
          let eventOptionsIndex = 0;
          eventOptionsIndex = eventOptions.options.findIndex(option => option.name === location.state.events[0]?.name);
          if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
            setEventCodeFilter(events);
            setBackgroundEventIndexState(eventOptionsIndex);
          }
          backgroundEventIndexHandled = true;
        }

        if (eventsHandled === true && backgroundEventIndexHandled === true) {
          setState({ ...state, initialLoadComplete: true });
        }
      }
      //if there is not location.state, consider the page loaded
      else {
        setState({ ...state, initialLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialLoadComplete, eventOptions, formState.events])

  useEffect(() => {
    if (!location.state && dateRangeOptions.options?.length > 1 && state.isDefaultDateRangeSet === false &&
      (formState.dateRangeId === Constants.DEFAULT_ID || formState.dateRangeName === '--')) {
      setFormData({
        ...formState,
        dateRangeId: dateRangeOptions.options[0]?.id,
        dateRangeName: dateRangeOptions.options[0]?.name
      });
      setState({ ...state, isDefaultDateRangeSet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeOptions, formState.dateRangeId, formState.dateRangeName])

  useEffect(() => {
    if (!location.state && competitionCategoryOptions.options?.length > 1 && state.isDefaultCompetitionCategorySet === false &&
      (formState.competitionCategoryId === Constants.DEFAULT_ID || formState.competitionCategoryName === '--')) {
      setFormData({
        ...formState,
        competitionCategoryId: competitionCategoryOptions.options[1]?.id,
        competitionCategoryName: competitionCategoryOptions.options[1]?.name
      });
      setState({ ...state, isDefaultCompetitionCategorySet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionCategoryOptions, formState.competitionCategoryId, formState.competitionCategoryName])

  useEffect(() => {
    if (formState.dateRangeName) {
      if (formState.dateRangeName !== '--') {
        const dateRange = filterFactory.members(DM.NamedDateRange.NamedDateRangeName, [formState.dateRangeName]);
        setDateRangeFilter(dateRange);
      }
      else {
        setDateRangeFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.dateRangeName, data, isLoading])

  useEffect(() => {
    if (formState.competitionCategoryName) {
      if (formState.competitionCategoryName !== '--') {
        const competitionCategory = filterFactory.members(DM.EventCompetitionCategory.TypeName, [formState.competitionCategoryName]);
        setTypeNameFilter(competitionCategory);
      }
      else {
        setTypeNameFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.competitionCategoryName, data, isLoading])

  useEffect(() => {
    if (formState.courseName) {
      if (formState.courseName !== '--') {
        const course = filterFactory.members(DM.SwimEvent.CourseCode, [formState.courseName]);
        setCourseFilter(course);
      }
      else {
        setCourseFilter(null);
      }
      //If any previous filters have been set, then clear out the event selections when the course changes
      if (state.initialLoadComplete === true) {
        onFormValueChanged('events', []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.courseName])

  useEffect(() => {
    if (formState.events) {
      if (formState.events?.length > 0) {
        const eventsFormatted = formState.events.map(event => { return (event.name) });
        const events = filterFactory.members(DM.SwimEvent.EventCode, [eventsFormatted[0]]);
        setEventCodeFilter(events);
      }
      else {
        setEventCodeFilter(null);
      }
    }
  }, [formState.events])

  useEffect(() => {
    if (state.initialLoadComplete === true) {
      let backgroundEventIndexHandled = false;
      let eventOptionsIndex = 0;
      if (formState.events?.length === 0 && formState.courseName === '--') {
        if (eventOptions.options?.length > 0) {
          //Set an event code in the background to avoid issues with maxResults
          eventOptionsIndex = eventOptions.options.findIndex(option => option.name === `50 FR SCM`);
          if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
            setEventCodeFilter(events);
          }
          backgroundEventIndexHandled = true;
        }
        if (backgroundEventIndexHandled) {
          setBackgroundEventIndexState(eventOptionsIndex);
        }
      }
      else if (formState.events?.length === 0 && formState.courseName !== '--') {
        if (eventOptions.options?.length > 0) {
          //Set an event code in the background to avoid issues with maxResults
          eventOptionsIndex = eventOptions.options.findIndex(option => option.name === `50 FR ${formState.courseName}`);
          if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
            const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
            setEventCodeFilter(events);
          }
          backgroundEventIndexHandled = true;
        }
        if (backgroundEventIndexHandled) {
          setBackgroundEventIndexState(eventOptionsIndex);
        }
      }
      else if (eventOptions.options?.length > 0 && formState.events?.length > 0) {
        //Set an event code equal to the first item in the formState.events array to avoid issues with maxResults
        let eventOptionsIndex = 0;
        eventOptionsIndex = eventOptions.options.findIndex(option => option.name === formState.events[0]?.name);
        if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
          const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
          setEventCodeFilter(events);
        }
        backgroundEventIndexHandled = true;
        if (backgroundEventIndexHandled) {
          setBackgroundEventIndexState(eventOptionsIndex);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.events, formState.courseId, formState.courseName, eventOptions, state.initialLoadComplete])

  useEffect(() => {
    if (bestTimeOnlyOptions.options?.length > 0 && (formState.displayId === Constants.DEFAULT_ID || formState.displayName === '--')) {
      setFormData({
        ...formState,
        displayId: bestTimeOnlyOptions.options[0]?.id,
        displayName: bestTimeOnlyOptions.options[0]?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestTimeOnlyOptions, formState.displayId, formState.displayName])

  useEffect(() => {
    if (formState.displayName && environmentVariableState.isLoaded === true) {
      if (formState.displayName !== '--') {
        const display = formState.displayName === SISENSE_SHOW_ALL_TIMES_TEXT ? null :
          formState.displayName === SISENSE_SHOW_BEST_TIMES_ONLY_TEXT ?
            filterFactory.equals(DM.NationalTeamSelection.IsBestTime, true)
            : null;
        setDisplayFilter(display);
      }
      else {
        setDisplayFilter(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.displayName, environmentVariableState])

  useEffect(() => {
    //if any of the selections change, set the offset back to 0
    let eventOptionsIndex = 0
    if (formState.events?.length === 0 && formState.courseName === '--') {
      if (eventOptions.options?.length > 0) {
        eventOptionsIndex = eventOptions.options.findIndex(option => option.name === '50 FR SCM');
        if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
          const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
          setEventCodeFilter(events);
          setBackgroundEventIndexState(eventOptionsIndex);
        }
      }
    }
    else if (formState.events?.length === 0 && formState.courseName !== '--') {
      if (eventOptions.options?.length > 0) {
        //Set an event code in the background to avoid issues with maxResults
        eventOptionsIndex = eventOptions.options.findIndex(option => option.name === `50 FR ${formState.courseName}`);
        if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
          const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
          setEventCodeFilter(events);
          setBackgroundEventIndexState(eventOptionsIndex);
        }
      }
    }
    else if (eventOptions.options?.length > 0 && formState.events?.length > 0) {
      //Set an event code equal to the first item in the formState.events array to avoid issues with maxResults
      let eventOptionsIndex = 0;
      eventOptionsIndex = eventOptions.options.findIndex(option => option.name === formState.events[0]?.name);
      if (eventOptionsIndex > -1 && eventOptions.options[eventOptionsIndex]?.name) {
        const events = filterFactory.members(DM.SwimEvent.EventCode, [eventOptions.options[eventOptionsIndex]?.name]);
        setEventCodeFilter(events);
        setBackgroundEventIndexState(eventOptionsIndex);
      }
    }
    setState({ ...state, offset: 0, pageNumber: 1, button: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (data && isLoading === false) {
      const dataCopy = JSON.parse(JSON.stringify(data));
      const newFormattedData = { columns: [], rows: [] };

      const newColumnArray = dataCopy.columns?.map((x, j) => {
        if (j < 11) {
          return (
            { ...x, columnNumber: j + 2 }
          )
        }
        else if (j === 11) {
          return (
            { ...x, columnNumber: 1 }
          )
        }
        else if (j > 11) {
          return (
            { ...x, columnNumber: j + 1 }
          )
        }
      })

      newFormattedData.columns = newColumnArray.sort((a, b) => (a.columnNumber - b.columnNumber));

      for (let i = 0; i < dataCopy.rows?.length; i++) {
        const newRowObject = dataCopy.rows[i]?.map((x, j) => {
          if (j < 11) {
            return (
              { ...x, columnNumber: j + 2 }
            )
          }
          else if (j === 11) {
            return (
              { ...x, columnNumber: 1 }
            )
          }
          else if (j > 11) {
            return (
              { ...x, columnNumber: j + 1 }
            )
          }
        });

        const newRowObjectSorted = newRowObject.sort((a, b) => (a.columnNumber - b.columnNumber));
        newFormattedData.rows.push(newRowObjectSorted);
      }

      //filter on rank according to maxResults
      const newFormattedDataFiltered = newFormattedData.rows.filter((row) => (row[0].data <= ToIntIfInt(formState.maxResults)))

      setFormattedData({
        ...formattedData,
        columns: newFormattedData.columns,
        rows: newFormattedDataFiltered
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formState.maxResults, state.button])

  return {
    isLoading,
    isError,
    data,
    state,
    formattedData,
    onFiltersToggle,
    goToNextPage,
    goToPreviousPage,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    eventOptions,
    eventOptionsFilteredByCourse: eventOptions.options.filter((option) => option?.name?.includes(formState.courseName)),
    backgroundEventIndexState
  };
};

export default useNationalTeamTimesSearchWidget;