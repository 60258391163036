import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './TimeDetailsValidation';

import { navLinks } from '../UseManageTimes';

import useTimeData from '../../../../common/state/time/UseTimeData';
import useEventData from '../../../../common/state/event/UseEventData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';
import formatCrossUiRoute from '../../../../common/utils/FormatCrossUiRoute';

const useTimesDetails = (isReadOnly) => {
  const navigate = useNavigate();
  const { timeFiltersState, timeState, sessionState, putTime, deleteTime } = useTimeData();
  const { eventState } = useEventData();
  const [state, setState] = useState(initialLocalState());
  const [uiState, setUiState] = useState({ deleteClicked: false });
  const { formState, errorState, setFormData, onFormValueChanged, onValueTextPairChanged, handleSubmit, resetForm }
    = useForm(initialFormState, submitFormCallback, validate);

  const createTimeObjForSubmit = (state) => {
    return {
      swimTimeId: state.swimTimeId,
      personId: timeState.objData.personId,
      eventId: state.eventId,
      meetId: state.meetId,
      sessionId: state.sessionId,
      heatNumber: timeState.objData.heatNumber,
      time: state.swimTimeValue,
      swimDate: state.swimDate,
      finishPosition: parseInt(state.place),
      isLeadOffTime: state.isRelayLeadOff,
      seedTime: state.entryTimeValue,
      eventCompetitionGenderTypeId: state.eventGenderId,
      teamName: timeState.objData.teamName,
      swimTimeRelay: timeState.objData.swimTimeRelay
    };
  };

  const formatCompetitorsAsString = (timeObj) => {
    if (timeObj.competitor === null
      && timeObj.relayTeam !== null) {
      const competitors = timeObj.relayTeam.map((teamMembers) => {
        return `${teamMembers.firstName} ${teamMembers.lastName}`;
      }).join(', ');
      return competitors;
    } else if (timeObj.competitor !== null
      && timeObj.relayTeam === null) {
      return timeObj.competitor;
    }
  };

  // const onBackToSearch = () => {
  //   navigate(navLinks.SEARCH,
  //     {
  //       filterState: timeFiltersState.filterObject,
  //       sortBy: timeFiltersState.sortBy
  //     }
  //   );
  // };

  const onShowPopup = () => {
    setState({
      ...state,
      showPopup: true
    });
  };

  const onClosePopup = () => {
    setState({
      ...state,
      showPopup: false
    });
  };

  const onDelete = () => {
    onClosePopup();
    setUiState({
      ...uiState,
      deleteClicked: true
    });

    deleteTime(timeState.objData.swimTimeId);
  };

  useEffect(() => {
    if (timeState.isObjLoaded === true && timeState.isSaving !== true && uiState.deleteClicked === true) {
      if ((timeFiltersState.backToSystem || '').toLowerCase() === 'meet') {
        const relativePath = `/meet/byid/${timeState.objData.meetId}/times`;
        const formattedUrlObject = formatCrossUiRoute(Constants.UI_PROJECT_NAMES.MEET, relativePath, false);

        window.location.href = formattedUrlObject.route;
      } else {
        navigate(navLinks.SEARCH);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState, uiState])

  useEffect(() => {
    if (Object.keys(timeState.objData).length > 0) {
      setState({
        ...state,
        competitor: formatCompetitorsAsString(timeState.objData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState.objData]);

  useEffect(() => {
    if (Object.keys(timeState.objData).length > 0 && 
      timeState.isObjLoaded === true && 
      (sessionState.isArrayLoaded === true || isReadOnly === true)) {
      const timeObj = { ...timeState.objData };
      setFormData({
        ...formState,
        swimTimeId: timeObj.swimTimeId || Constants.DEFAULT_ID,
        meetId: timeObj.meetId || Constants.DEFAULT_ID,
        meetName: timeObj.meet?.meetName || '',
        eventId: timeObj.eventId || Constants.DEFAULT_ID,
        eventName: timeObj.event?.eventName || '',
        eventGenderId: timeObj.eventCompetitionGenderTypeId || Constants.DEFAULT_ID,
        eventGenderName: timeObj.eventCompetitionGenderType?.typeName,
        swimDate: timeObj.swimDate ? formatDate(timeObj.swimDate) : Constants.BLANK_DATE_STRING,
        place: (timeObj.finishPosition === null ? '' : timeObj.finishPosition),
        sessionId: timeObj.sessionId || Constants.DEFAULT_ID,
        sessionName: (timeObj.session === null ? '' : timeObj.session?.typeName),
        entryTime: (timeObj.seedTime === null ? 'NT' : formatTimeForDisplay(timeObj.seedTime)),
        entryTimeValue: timeObj.seedTime === null ? 'NT' : timeObj.seedTime,
        swimTime: (timeObj.time === null ? 'NT' : formatTimeForDisplay(timeObj.time)),
        swimTimeValue: timeObj.time === null ? 'NT' : timeObj.time,
        isRelayLeadOff: (timeObj.isLeadOffTime === null || timeObj.isLeadOffTime === false ? false : true),
        meetStartDate: timeObj.meet?.startDate ? formatDate(timeObj.meet?.startDate) : Constants.BLANK_DATE_STRING,
        meetEndDate: timeObj.meet?.endDate ? formatDate(timeObj.meet?.endDate) : Constants.BLANK_DATE_STRING
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState.isObjLoaded, sessionState.isArrayLoaded]);

  function submitFormCallback(formState) {
    const timeObj = createTimeObjForSubmit(formState);

    putTime(timeObj.swimTimeId, timeObj);
  };

  function initialFormState() {
    return {
      swimTimeId: Constants.DEFAULT_ID,
      meetId: Constants.DEFAULT_ID,
      meetName: '',
      competitor: '',
      eventId: Constants.DEFAULT_ID,
      eventName: '',
      eventGenderId: Constants.DEFAULT_ID,
      eventGenderName: '',
      swimDate: Constants.BLANK_DATE_STRING,
      place: '',
      sessionId: Constants.DEFAULT_ID,
      sessionName: '',
      entryTime: '',
      entryTimeValue: '',
      swimTime: '',
      swimTimeValue: '',
      isRelayLeadOff: false,
      meetStartDate: null,
      meetEndDate: null
    };
  };

  function initialLocalState() {
    return {
      showPopup: false,
      competitionGenderOptions: [
        { id: 1, name: Constants.COMP_GENDER_OPTIONS_MALE_NAME },
        { id: 2, name: Constants.COMP_GENDER_OPTIONS_FEMALE_NAME },
        { id: 3, name: Constants.COMP_GENDER_OPTIONS_MIXED_NAME }
      ]
    };
  };

  return {
    state,
    formState,
    errorState,
    timeFiltersState,
    timeState,
    eventState,
    onFormValueChanged,
    onValueTextPairChanged,
    onSubmit: handleSubmit,
    //onBackToSearch,
    resetForm,
    onDelete,
    onClosePopup,
    onShowPopup
  };
};

export default useTimesDetails;