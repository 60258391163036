import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import searchTimeStandards from './SearchTimeStandardsData';
import getTimeStandard from './GetTimeStandardData';
import postTimeStandard from './PostTimeStandardData';
import putTimeStandard from './PutTimeStandardData';
import deleteTimeStandard from './DeleteTimeStandardData';
import deleteTimeStandardAgeGroup from './DeleteTimeStandardAgeGroupData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimeStandardData = {
  INITIAL_STATE,
  searchTimeStandards,
  getTimeStandard,
  postTimeStandard,
  putTimeStandard,
  deleteTimeStandard,
  deleteTimeStandardAgeGroup
};

export default TimeStandardData;