import { useContext } from 'react';

import TimeStandardData from './TimeStandardData';
import { TimeStandardStateContext } from './TimeStandardContextProvider';
import { TimeStandardFiltersStateContext } from './TimeStandardFiltersContextProvider';

const useTimeStandardData = () => {
  const [timeStandardState, setTimeStandardState] = useContext(TimeStandardStateContext);
  const [timeStandardFiltersState, setTimeStandardFiltersState] = useContext(TimeStandardFiltersStateContext);

  const searchTimeStandards = (filterObject, sortBy) => {
    const stringFilterObject = JSON.stringify(filterObject);
    setTimeStandardFiltersState({ ...timeStandardFiltersState, filterObject: stringFilterObject, sortBy });
    const stringFilterObjectForSearch = {
      orgUnitId: filterObject.orgUnit ? filterObject.orgUnit[0].id.toString() : filterObject.orgUnit,
      timeStandardTypeId: filterObject.timeStandardTypeId,
      timeStandardName: filterObject.timeStandardName,
      isCurrent: filterObject.isCurrent
    };
    TimeStandardData.searchTimeStandards(JSON.stringify(stringFilterObjectForSearch), sortBy, timeStandardState, setTimeStandardState);
  };

  const getTimeStandard = (timeStandardId) => {
    TimeStandardData.getTimeStandard(timeStandardId, timeStandardState, setTimeStandardState);
  };

  const postTimeStandard = (timeStandardObj) => {
    TimeStandardData.postTimeStandard(timeStandardObj, timeStandardState, setTimeStandardState);
  };

  const putTimeStandard = (timeStandardId, timeStandardObj) => {
    TimeStandardData.putTimeStandard(timeStandardId, timeStandardObj, timeStandardState, setTimeStandardState);
  };

  const deleteTimeStandard = (timeStandardId) => {
    TimeStandardData.deleteTimeStandard(timeStandardId, timeStandardState, setTimeStandardState);
  };

  const deleteTimeStandardAgeGroup = (timeStandardAgeGroupId) => {
    TimeStandardData.deleteTimeStandardAgeGroup(timeStandardAgeGroupId, timeStandardState, setTimeStandardState);
  };

  const clearObjData = () => {
    setTimeStandardState({
      ...timeStandardState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearSearchResults = () => {
    setTimeStandardState({
      ...timeStandardState,
      isArrayloaded: false,
      isArrayloading: false,
      arrayData: [],
      message: ''
    })
  };

  return {
    timeStandardState,
    timeStandardFiltersState,
    searchTimeStandards,
    getTimeStandard,
    postTimeStandard,
    putTimeStandard,
    deleteTimeStandard,
    deleteTimeStandardAgeGroup,
    clearObjData,
    clearSearchResults
  };
};

export default useTimeStandardData;