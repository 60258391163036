import { Fragment } from 'react';
import { useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useTimeStandardDetail from './UseTimeStandardDetail';

import TimeStandardTypeDropdown from '../../../components/dropdowns/TimeStandardTypeDropdown';

import Headings from '../../../../common/components/headings/Headings';
import Textarea from '../../../../common/components/inputs/Textarea';
import Input from '../../../../common/components/inputs/Input';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import OrgUnitTreeView from '../../../../common/components/tree/orgUnitTree/OrgUnitTreeView';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../TimeStandards.module.css';

const TimeStandardDetail = () => {
  const location = useLocation();
  const { state, fileState, timeStandardState, timeFilesLogoState, orgUnitState, timeStandardTypeState, formState, errorState,
    handleSubmit, onValueTextPairChanged, onFormValueChanged, onOrgUnitChanged, onCancelClicked,
    onUploadLogoClicked, onDownloadUploadedFile, removeFileModalState, onRemoveLogoClicked,
    onRemoveFileModalCanceled, onOpenRemoveFileModalClicked } = useTimeStandardDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{location.state && location.state.timeStandardId ? "Edit" : "Add"} Time Standard</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>Logo (Optional)</Headings.H6>
        </div>
      </div>
      <Fragment>
        {fileState.logoFileUrl === '' ?
          <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <label htmlFor="logo-file-upload" className={global.FileUpload}>
                  Upload Logo
                </label>&nbsp;(*.pdf), (*.jpg), or (*.png)
              </div>
            </div>
            <input hidden id="logo-file-upload" type="file" onChange={onUploadLogoClicked} />
          </Fragment>
          : <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                {fileState.logoFileName.includes("Bad Request") === false &&
                  <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
                }
              </div>
            </div>
          </Fragment>}
        <div className="row usas-extra-bottom-margin" hidden={fileState.logoFileUrl === ''}>
          <div className="col-xs-12">
            {fileState.logoFileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {fileState.logoFileName}</p>
            }
          </div>
        </div>
        {state.error ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
            </div>
          </div> : <Fragment />}
      </Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <OrgUnitTreeView
              label="Organization*"
              name="orgUnit"
              value={formState.orgUnit}
              error={errorState.orgUnit}
              message={errorState.orgUnit}
              selectableLeavesOnly={false}
              singleSelect={true}
              onChange={(newValue) => { onOrgUnitChanged(newValue) }}
              organizationId={undefined} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Time Standard Name*"
              name="standardName"
              value={formState.standardName}
              error={errorState.standardName}
              message={errorState.standardName}
              onChange={(value) => { onFormValueChanged('standardName', value) }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeStandardTypeDropdown
              label="Time Standard Type*"
              name="timeStandardTypeId"
              value={formState.timeStandardTypeId}
              error={errorState.timeStandardTypeId}
              message={errorState.timeStandardTypeId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardTypeId', newValueLabel, 'timeStandardTypeName', e); }}
              orgUnitId={formState.orgUnit[0]?.id || ''}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Textarea
              className={style.TextareaSize}
              label="Description*"
              name="standardDescription"
              value={formState.standardDescription}
              error={errorState.standardDescription}
              message={errorState.standardDescription}
              onChange={(value) => { onFormValueChanged('standardDescription', value) }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Qualifying Start Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
              countOfYears={160}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Qualifying End Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }}
              countOfYears={160}
              startYearOffset={-149} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isArrayLoading ||
          (timeStandardState.isObjLoading && !timeStandardState.isSaving) ||
          orgUnitState.isArrayLoading || timeStandardTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving || timeFilesLogoState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Logo</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onRemoveLogoClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  )
}

export default TimeStandardDetail;
