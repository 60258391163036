import { useEffect, useState } from 'react';

import validate from './SearchMyTimeStandardsValidation';

import useSearchTimeStandardData from '../../state/searchTimeStandard/UseSearchTimeStandardData';
import useTimeStandardData from '../../state/timeStandard/UseTimeStandardData';

import useTimeStandardTypeData from '../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../common/utils/UseForm';
import Constants from '../../../common/utils/Constants';
import DeleteTimeStandardAgeGroupData from '../../state/timeStandard/DeleteTimeStandardAgeGroupData';

const useSearchMyTimeStandards = () => {
  const [state, setState] = useState({
    tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID,
    tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: false
  });
  const { searchTimeStandardState, setSearchTimeStandardState } = useSearchTimeStandardData();
  const { timeStandardState, timeStandardFiltersState, searchTimeStandards, clearObjData, getTimeStandard, putTimeStandard,
    deleteTimeStandard, deleteTimeStandardAgeGroup } = useTimeStandardData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged, setFormState, setErrors } = useForm(getInitialFormState, submitFormCallback, validate);

  const createSearchFilterObject = () => {
    const filterObject = {
      orgUnit: [{ id: selectOrgUnitState.orgUnitId, name: selectOrgUnitState.orgUnitName }],
      timeStandardTypeId: (formState.timeStandardTypeId === Constants.DEFAULT_ID) ? undefined : formState.timeStandardTypeId,
      timeStandardName: (formState.timeStandardName === '') ? undefined : formState.timeStandardName,
    }

    return filterObject;
  }

  function submitFormCallback(formState) {
    setSearchTimeStandardState({ ...searchTimeStandardState, tryRedirect: false });
    searchTimeStandards(createSearchFilterObject(), '');
  }

  const clearForm = () => {
    searchTimeStandards({ orgUnit: [{ id: selectOrgUnitState.orgUnitId, name: selectOrgUnitState.orgUnitName }] }, '');
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onDeleteTimeStandard = (e, timeStandardId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, tryTimeStandardDelete: true, timeStandardId: timeStandardId });
  }

  const editTimeStandardAgeGroupArray = (timeStandardAgeGroupId) => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    const index = timeStandardCopy.timeStandardAgeGroup.findIndex(e => e.timeStandardAgeGroupId === timeStandardAgeGroupId);

    timeStandardCopy.timeStandardAgeGroup.splice(index, 1);

    return timeStandardCopy;
  }

  const onDeleteAgeGroup = (e, timeStandardId, timeStandardAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    getTimeStandard(timeStandardId);
    setState({ ...state, tryAgeGroupDelete: true, timeStandardAgeGroupId: timeStandardAgeGroupId });
  }

  useEffect(() => {
    // initial search on page load
    if (Object.keys(timeStandardFiltersState.filterObject).length === 0) {
      searchTimeStandards(createSearchFilterObject(), '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check for previous search values to repopulate the form
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0) {
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      searchTimeStandards(oldSearchValues, '');
      setFormState({
        ...formState,
        ...oldSearchValues,
        sortBy: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //handle deletes
    if (Object.keys(timeStandardState.objData).length > 0) {
      //delete age group
      if (state.tryAgeGroupDelete === true && state.timeStandardAgeGroupId !== Constants.DEFAULT_ID) {
        deleteTimeStandardAgeGroup(state.timeStandardAgeGroupId);
        setState({ ...state, tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: true });
      }
      //delete time standard
      if ((state.tryTimeStandardDelete === true && state.timeStandardId !== Constants.DEFAULT_ID)) {
        clearObjData();
        deleteTimeStandard(state.timeStandardId);
        setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, redoSearch: true });
      }
    }
    else {
      //delete time standard
      if ((state.tryTimeStandardDelete === true && state.timeStandardId !== Constants.DEFAULT_ID)) {
        deleteTimeStandard(state.timeStandardId);
        setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, redoSearch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData, state]);

  useEffect(() => {
    // Check for previous search values to repopulate the form after a successful delete
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0
      && timeStandardState.isObjLoading === false && state.redoSearch === true) {
      clearObjData();
      setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: false });
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      searchTimeStandards(oldSearchValues, timeStandardFiltersState.sortBy);
      setFormState({
        ...formState,
        ...oldSearchValues,
        sortBy: timeStandardFiltersState.sortBy
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.isObjLoading, state.redoSearch]);

  function getInitialFormState() {
    return {
      orgUnit: [],
      timeStandardTypeId: Constants.DEFAULT_ID,
      timeStandardTypeName: '',
      timeStandardId: Constants.DEFAULT_ID,
      timeStandardName: ''
    };
  };

  return {
    timeStandardState,
    timeStandardTypeState,
    selectOrgUnitState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onDeleteTimeStandard,
    onDeleteAgeGroup,
    clearForm
  }
};

export default useSearchMyTimeStandards;