import { useEffect, useState } from 'react';

import { useExecuteQuery } from '@sisense/sdk-ui';
import { Sort } from '@sisense/sdk-data';

import * as DM from '../../../../fina-times-data';

const useSisenseDateRangeDropdown = () => {
    const queryProps = { dataSource: DM.DataSource, dimensions: [DM.NamedDateRange.NamedDateRangeKey.sort(Sort.Ascending), DM.NamedDateRange.NamedDateRangeName] };
    const { data } = useExecuteQuery(queryProps);
    const [dateRangeOptions, setDateRangeOptions] = useState({ options: [] });

    useEffect(() => {
        const defaultOptions = [];
        if (data) {
            const formattedOptions = [];
            data.rows.map((row) => {
                return (formattedOptions.push({ id: row[0].data, name: row[1].text }))
            });
            setDateRangeOptions({ ...dateRangeOptions, options: formattedOptions });
        }
        else {
            setDateRangeOptions({ ...dateRangeOptions, options: defaultOptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return {
        data,
        dateRangeOptions
    };
};

export default useSisenseDateRangeDropdown;