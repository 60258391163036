import useSisenseBestTimeOnlyDropdown from "./UseSisenseBestTimeOnlyDropdown";

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';

const SisenseBestTimeOnlyDropdown = ({ label, name, value, error, message, onChange }) => {
  const { bestTimeOnlyOptions } = useSisenseBestTimeOnlyDropdown();

  return bestTimeOnlyOptions.options?.length === 0
    ? <span className={global.LoadingMsg}>{Constants.LOADING_MSG}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={bestTimeOnlyOptions.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={bestTimeOnlyOptions.options?.length === 0}
      />
    );
};

export default SisenseBestTimeOnlyDropdown;